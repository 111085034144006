import './App.css';

function App() {
	return (
		<div className="App">
			yo dawg whats up hello world!
		</div>
	);
}
export default App;
